<template>
    <div
        @click="vote"
        :class="[
            'vote-option-wrapper  relative transition overflow-hidden',
            status === 'closed' || !connected ? 'expired' : '',
            (voting || userHasVotedForThis) ? 'cursor-not-allowed' : 'cursor-pointer'
        ]"
    >
        <div
            :class="['absolute top-0 left-0 z-10 h-full bg-gray-dark']"
            :style="percentageStyle"
        >
            &nbsp;
        </div>
        <div class="relative z-20">
            <div class="flex justify-between">
                <h3
                    class="text-white"
                    style="
                        font-size: 12px;
                        font-weight: 700;
                        letter-spacing: 0.09em;
                    "
                >
                    {{ title }}
                    <span
                        v-if="userHasVotedForThis"
                        class="ml-2 inline-flex items-center px-1 py-1 rounded-full font-medium bg-dark-lighter text-green-600"
                        style="font-weight: 700; font-size: 10px"
                    >
                        <i class="fa-solid fa-check"></i>
                    </span>
                    <span
                        v-if="voting"
                        class="ml-2 inline-flex items-center px-1 py-1 rounded-full font-medium bg-dark-lighter text-white"
                        style="font-weight: 700; font-size: 10px"
                    >
                        <i class="fa-solid fa-spinner-third fa-spin"></i>
                    </span>
                </h3>
                <p class="text-gray" style="font-size: 14px; font-weight: 400">
                    {{ optionVotesAsPercentage }} Of Votes
                </p>
            </div>
            <p
                class="text-gray"
                style="
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.09em;
                "
            >
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
import reduce from "lodash/reduce";


export default {
    name: "VoteOption",
    props: {
        title: {},
        description: {},
        votes: {},
        status: {},
        proposal_id:{},
        voting:{}
    },
    computed: {
        connected() {
            return this.$store.state.authenticated_wallet_address;
        },
        totalVotes() {
            return reduce(
                this.votes,
                (result, item) => {
                    result += parseInt(item.tokens);
                    return result;
                },
                0
            );
        },
        optionVotes() {
            return reduce(
                this.votes,
                (result, item) => {
                    if (item.option === this.title) {
                        result += parseInt(item.tokens);
                    }
                    return result;
                },
                0
            );
        },
        optionVotesAsPercentage() {
            if (this.totalVotes === 0) return "0%";
            return ((this.optionVotes / this.totalVotes) * 100).toFixed() + "%";
        },
        percentageStyle() {
            return {
                width: this.optionVotesAsPercentage,
            };
        },
        userHasVotedForThis() {
            if (!this.$store.state.authenticated_wallet_address) {
                return false;
            }

            return reduce(
                this.votes,
                (result, item) => {
                    if (
                        item.option === this.title &&
                        item.wallet_address.toLowerCase() ===
                            this.$store.state.authenticated_wallet_address.toLowerCase()
                    ) {
                        result = true;
                    }
                    return result;
                },
                false
            );
        },
    },
    methods: {
        async vote() {
            if(this.userHasVotedForThis) return;
            this.$emit('vote', this.title);
        },
    },
};
</script>

<style lang="scss" scoped>
.vote-option-wrapper {
    border: 1px solid #222440;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.vote-option-wrapper:hover {
    border-color: #2b43c2;
}
.vote-option-wrapper.expired:hover {
    border-color: #222440 !important;
    cursor: default !important;
}
</style>
