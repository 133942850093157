<template>
    <li  class="text-gray grid grid-cols-12 w-full">
        <div class="col-span-4">
            <p class="truncate ">{{vote.wallet_address}}</p>
        </div>
        <div class="col-span-4 text-center">
            <p >{{vote.option}}</p>
        </div>
        <div class="col-span-4 text-right">
            <p >{{tokens}}</p>
        </div>
    </li>
</template>

<script>
    export default {
        name:"VoteHistoryItem",
        props:{
            vote:{}
        },
        computed:{
            tokens(){
                return this.vote.tokens;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
