import './bootstrap';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import store from './store';
import Layout from './components/Layout.vue'

createInertiaApp({
    resolve: name => require(`./pages/${name}`),
    setup({ el, App, props, plugin }) {
      createApp({ render: () => h(App, props) })
        .use(store)
        .use(plugin)
        .mount(el)
    },
  })


