<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-30" @close="close">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div
                    class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative bg-dark rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
                        >
                            <div>
                                <div
                                    class="mx-auto flex items-center justify-center h-12 w-12 rounded-full "
                                    style="background: #24293C"
                                >
                                    <i
                                        class="fa-solid fa-triangle-exclamation text-white"
                                    ></i>
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle
                                        as="h3"
                                        class="text-xl leading-6 font-medium text-white"
                                    >
                                        Insufficient Balance
                                    </DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-md text-gray">
                                            The connected wallet has 0
                                            $Shiryo-Inu. To vote you need to
                                            hold at least 1 $Shiryo-Inu token.
                                            Click "Buy Now" to buy Shiryo Inu
                                            tokens now.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                            >
                                <Badge neutral @click="close" class="text-center cursor-pointer">
                                    <i class="fa-solid fa-angle-left mr-2"></i>
                                    Go Back
                                </Badge>
                                <Badge primary class="text-center cursor-pointer" @click="buyNow">
                                    Buy Now<i
                                        class="fa-solid fa-right ml-2"
                                    ></i>
                                </Badge>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import Badge from "./Badge.vue";

export default {
    name: "NoTokensModal",
    methods: {
        close() {
            this.$store.commit('setShowNoBalanceModal', false);
        },
        buyNow(){
            window.open("https://app.uniswap.org/#/swap?outputCurrency=0x1E2F15302B90EddE696593607b6bD444B64e8F02&chain=mainnet", '_blank');
        }
    },
    computed:{
        open(){
            return this.$store.state.showNoBalanceModal;
        }
    },
    components: {
        Badge,
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
};
</script>
