<template>
    <Badge neutral class="sm:ml-4 ">
       <p v-if="status==='loading'" class="text-gray text-center" style="width: 245px">
            <i class="fa-solid fa-spinner-third fa-spin"></i>
        </p>
        <p v-else class="text-gray text-center" style="width: 245px">{{days}} days {{hours}} hours {{mins}} mins {{secs}} secs</p>
    </Badge>
</template>

<script>
    import Badge from './Badge.vue'

    export default {
        name:"Countdown",
        props:{
            endtime:{},
            status:{}
        },
        components:{
            Badge
        },
        data(){
            return {
                interval: false,
                hours: "00",
                mins: "00",
                secs: "00",
                days: "00"
            }
        },
        computed:{
            countdownDate(){
                return new Date(this.endtime).getTime();
            }
        },
        mounted(){
            var now = new Date().getTime();

            this.interval = setInterval(() => {
                var now = new Date().getTime();
                var timeleft = this.countdownDate - now;

                if(timeleft <= 0){
                    this.hours = "00";
                    this.mins = "00";
                    this.secs = "00";
                    clearInterval(this.interval);
                    this.$emit('expired', true);
                    return;
                }
                this.$emit('expired', false);

                var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

                if(days < 9 ) days = "0" + days
                if(hours < 9 ) hours = "0" + hours
                if(minutes < 9 ) minutes = "0" + minutes
                if(seconds < 9 ) seconds = "0" + seconds

                this.days = days
                this.hours = hours
                this.mins = minutes
                this.secs = seconds
                }, 1000);
        }
    }
</script>

<style lang="scss" scoped>
.countdown {
    font-size: 14px;
    line-height: 12.43px;
    font-weight: 400;
    margin-left: 10px;
    padding: 10px 20px;
}
</style>
