
import Web3 from 'web3/dist/web3.min.js'


export const getBalance = async ({wallet_address, contract_address, chain}) => {
    const provider = (chain === 'eth') ? "https://dawn-side-fire.quiknode.pro/d24679aa2625bc2fad4991da103578639050399c/" : "https://speedy-nodes-nyc.moralis.io/30815c6ca7f2940676fe1d46/polygon/mainnet";
    const Web3Client = new Web3(new Web3.providers.HttpProvider(provider));

    // The minimum ABI required to get the ERC20 Token balance
    const minABI = [
    // balanceOf
    {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        type: "function",
    },
    ];

    const contract = new Web3Client.eth.Contract(minABI, contract_address);
    const result = await contract.methods.balanceOf(wallet_address).call(); // 29803630997051883414242659

    const format = Web3Client.utils.fromWei(result, 'nano'); // 29803630.997051883414242659

    return format;
}
