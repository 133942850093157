<template>
    <div class="grid grid-cols-1 justify-start lg:justify-end items-center sm:flex pt-4 lg:pt-0">
        <div class="pb-4 sm:pb-0">
            <Badge v-if="status==='closed'" neutral>CLOSED</Badge>
            <Badge v-else-if="status==='open'" primary>OPEN</Badge>
            <Badge v-else neutral><i class="fa-solid fa-spinner-third fa-spin"></i></Badge>
        </div>
        <div>
            <Countdown :endtime="proposal.end_time" @expired="setExpired"  :status="status"/>
        </div>
    </div>
</template>

<script>
    import Badge from "./Badge.vue"
    import Countdown from './Countdown.vue'

export default {
    name: "ProposalMeta",
    components:{
         Badge, Countdown
    },
    props:{
        proposal: Object
    },
    data() {
        return {
            status: 'loading',
        };
    },
    methods: {
        setExpired(value) {
            if(value) {
                this.status = 'closed'
            }else {
                this.status = 'open'
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
