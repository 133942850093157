<template>
    <div :class="['label text-white', (primary) ? 'bg-primary text-' : '', (neutral) ? 'bg-dark-lighter' : '']">
        <slot />
    </div>
</template>

<script>
    export default {
        name:"badge",
        props:{
            primary: {
                type: Boolean,
                default: false
            },
            neutral: {
                type: Boolean,
                default: false
            },
        }
    }
</script>

<style lang="scss" scoped>
.label {
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 12.43px;
    float: left;
}
</style>
