<template>
    <div class="min-h-screen w-screen">
        <header
            class="bg-dark-dark header flex justify-between w-screen items-center"
        >
            <img
                src="https://ipfs-fast.b-cdn.net/shiryo-dao/dao-logo.png"
                class="logo"
            />
            <div>
                <wallet-connect />
            </div>
        </header>
        <main class="w-screen lg:flex lg:justify-start">
            <aside
                class="bg-dark-dark sidebar grid grid-cols-2 lg:flex lg:flex-col lg:justify-between lg:py-12 w-screen pb-4"
            >
                <ul class="nav flex lg:block items-center justify-start text-left">
                    <li class="text-uppercase">
                        <Link
                            href="/"
                            :class="[
                                'hover:text-white',
                                $page.url === '/' ? 'text-white' : 'text-gray',
                            ]"
                            >Proposals</Link
                        >
                    </li>
                    <li class="text-uppercase">
                        <Link
                            href="/closed"
                            :class="[
                                'hover:text-white',
                                $page.url === '/closed'
                                    ? 'text-white'
                                    : 'text-gray',
                            ]"
                            >Archived</Link
                        >
                    </li>
                    <li class="text-uppercase">
                        <a
                            v-if="$store.state.isAdmin"
                            href="/nova"
                            class="text-gray hover:text-white"
                            target="_blank"
                            >Admin</a
                        >
                    </li>
                </ul>
                <div>
                    <div class="lg:flex justify-center mb-2 hidden">
                        <img
                            src="https://ipfs-fast.b-cdn.net/shiryo-dao/favicon.png"
                        />
                    </div>
                    <p
                        class="text-center text-gray mb-1 hidden lg:block"
                        style="font-size: 10px; line-height: 12px"
                    >
                        CURRENT PRICE
                    </p>
                    <p
                        v-if="loadingPrice"
                        class="text-white text-lg hidden lg:block"
                        style="font-size: 10px; line-height: 12px"
                    >
                        $<i class="fa-solid fa-spinner-third ml-2 fa-spin"></i>
                    </p>
                    <p
                        v-else
                        class="text-white text-lg hidden lg:block"
                        style="font-size: 10px; line-height: 12px"
                    >
                        ${{ currentPrice }}
                    </p>
                    <ul class="text-primary flex justify-end items-center lg:justify-between lg:mt-4 text-lg mx-8 lg:mx-0">
                        <li class="px-2">
                            <a
                                href="https://t.me/Shiryo_Official"
                                target="_blank"
                                ><i class="fa-brands fa-telegram"></i
                            ></a>
                        </li>
                        <li class="px-2">
                            <a
                                href="https://twitter.com/Shiryo_Official"
                                target="_blank"
                                ><i class="fa-brands fa-twitter"></i
                            ></a>
                        </li>
                        <li class="px-2">
                            <a href="https://shiryo.com" target="_blank"
                                ><i class="fa-solid fa-globe"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </aside>
            <section class="sm:p-12 p-8 bg-dark min-h-full flex-grow">
                <div class="max-w-7xl">
                    <slot />
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import WalletConnect from "./WalletConnect.vue";
import { Link } from "@inertiajs/inertia-vue3";
const math = require("mathjs");
import reduce from 'lodash/reduce';

export default {
    name: "Layout",
    components: {
        WalletConnect,
        Link,
    },
    data() {
        return {
            currentPrice: 0,
            loadingPrice: false,
        };
    },
    props: {
        user: {},
        isAdmin: {},
    },
    computed:{
    },
    async mounted() {
        if (this.user) {
            this.$store.commit("setAuthWalletAddress", this.user.eth_address);
            this.$store.commit('setIsAdmin', this.isAdmin);
        }
        this.loadingPrice = true;
        const req = await axios.get(
            "https://api.coingecko.com/api/v3/coins/ethereum/contract/0x1E2F15302B90EddE696593607b6bD444B64e8F02"
        );
        this.currentPrice = math.format(
            req.data.market_data.current_price.usd,
            {
                notation: "fixed",
            }
        );
        this.loadingPrice = false;

        window.ethereum.on('accountsChanged', async () => {
            this.$store.commit("setAuthWalletAddress", "");
            this.$store.commit("setIsAdmin", false);
            await axios.post("/nova/logout");
        });

        window.ethereum.on('chainChanged', async () => {
            this.$store.commit("setAuthWalletAddress", "");
            this.$store.commit("setIsAdmin", false);
            await axios.post("/nova/logout");
        });
    },
};
</script>

<style lang="scss" scoped>
.header {
    height: 70px;
}
main {
    min-height: calc(100vh - 70px);
}
.sidebar .nav li {
    padding-left: 30px;
}

@media (min-width: 1024px) {
.sidebar {
    width: 160px;
    padding-left: 30px;
    padding-right: 30px;

    .nav li {
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 0;
    }
}
}


.logo {
    margin-left: 28px;
}
</style>
