<template>
    <div class="bg-dark-lighter proposal-card h-full">
        <div class="proposal-card-header grid lg:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 grid-cols-1 items-center relative">
            <img src="https://ipfs-fast.b-cdn.net/shiryo-dao/fangs.png" class="fangs" />
            <div class="flex justify-start items-center">
                <img
                    src="https://ipfs-fast.b-cdn.net/shiryo-dao/shiryo-icon.png"
                />
                <h2 class="text-gray-lighter uppercase proposal-heading ml-4 font-extrabold">
                    {{ proposal.name }}<br/>
                    <span v-if="proposal.archived" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs   bg-dark-lighter text-gray font-extrabold"> Archived </span>
                </h2>
            </div>
            <div class="proposal-meta relative">
                <div class="relative z-20 flex justify-start">
                    <proposal-meta :proposal="proposal" />
                </div>
            </div>
        </div>
        <div class="text-gray proposal-description" v-html="proposal.description" />
    </div>
</template>

<script>
import ProposalMeta from "./ProposalMeta.vue";

export default {
    name: "ProposalCard",
    computed:{
        proposal(){
            return this.$store.getters.proposal;
        }
    },
    components: {
        ProposalMeta,
    },
};
</script>
<style lang="scss" scoped>
.proposal-card-header {
    background: #24293C;
    padding: 25px;
}
@media (min-width: 1024px) {
    .proposal-card-header {
        padding: 0px 35px;
        height:100px;
    }
 }
.proposal-card {
    border-radius: 10px;
    overflow: hidden;
}
@media (min-width: 1024px) {
    .proposal-card {
        margin-right: 35px;
    }
}
.proposal-description {
    padding: 35px;
}
.fangs {
    position: absolute;
    right: -35px;
    top: 0px;
    bottom: 0;
    z-index: 10;
}
</style>
<style>
.proposal-description p {
    margin-bottom: 15px;
}
</style>

