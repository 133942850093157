<template>
    <div class="bg-dark-lighter votes-card mt-12 lg:mt-0 mb-12">
        <div style="padding: 0px 35px">
            <h3
                class="text-white mb-4"
                style="
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 0.09em;
                "
            >
                <span v-if="status === 'loading'">
                    <i class="fa-solid fa-spinner-third fa-spin"></i>
                </span>
                <span v-if="status === 'open'">VOTE</span>
                <span v-if="status === 'closed'">VOTING ClOSED</span>
                <span
                    v-if="status === 'open' && !connected"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs ml-4 bg-dark text-gray font-extrabold"
                >
                    Connect Wallet To Vote
                </span>
            </h3>
        </div>
        <div style="padding: 0px 35px">
            <vote-option
                v-for="(description, title) in proposal.options"
                :key="title"
                :title="title"
                :description="description"
                :votes="votes"
                :status="status"
                :proposal_id="proposal.id"
                :voting="voting"
                @vote="vote"
            />
        </div>
        <div class="votes-card-divider">&nbsp;</div>
        <div class="votes-card-history">
            <vote-history :votes="votes" />
        </div>
    </div>
</template>

<script>
import VoteOption from "./VoteOption.vue";
import VoteHistory from "./VoteHistory.vue";
import filter from "lodash/filter";
import { getBalance } from "../utils";
import swal from "sweetalert2";
import reduce from 'lodash/reduce';

export default {
    name: "VotesCard",
    components: {
        VoteOption,
        VoteHistory,
    },
    data() {
        return {
            status: "loading",
            interval: false,
            voting: false,
        };
    },
    computed: {
        proposal() {
            return this.$store.getters.proposal;
        },
        votes() {
            return this.$store.getters.votesForProposal;
        },
        countdownDate() {
            return new Date(this.proposal.end_time).getTime();
        },
        connected() {
            return this.$store.state.authenticated_wallet_address;
        },
    },
    mounted() {
        this.interval = setInterval(() => {
            var now = new Date().getTime();
            var timeleft = this.countdownDate - now;

            if (timeleft <= 0) {
                clearInterval(this.interval);
                this.status = "closed";
            } else {
                this.status = "open";
            }
        }, 1000);
    },
    methods: {
        async vote(option) {
            if (this.voting) return;
            if (this.status !== "open") return;
            if (!this.$store.state.authenticated_wallet_address) return;

            this.voting = true;
            // Get user balance
            const ethBalance = await getBalance({
                wallet_address: this.$store.state.authenticated_wallet_address,
                contract_address: "0x1E2F15302B90EddE696593607b6bD444B64e8F02",
                chain: "eth",
            });
            const split = ethBalance.split(".");
            const rounded = split[0];

            if (rounded === "0") {
                this.$store.commit("setShowNoBalanceModal", true);
                this.voting = false;
                return;
            }

            try {
                const req = await axios.post("/vote", {
                    proposal_id: this.proposal.id,
                    tokens: rounded,
                    option: option,
                    wallet_address:
                        this.$store.state.authenticated_wallet_address,
                });

                this.$store.commit("setVotes", req.data.votes);

                this.voting = false;
            } catch (err) {
                swal.fire({
                    title: "Error!",
                    text: "We was unable to save your vote, please try again.",
                    icon: "error",
                    confirmButtonText: "Close",
                });
                this.voting = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.votes-card {
    border-radius: 10px;
    overflow: hidden;
    padding-top: 35px;
    padding-bottom: 35px;
}
.votes-card-history {
    padding: 0px 35px;
}
.votes-card-divider {
    margin-top: 35px;
    margin-bottom: 10px;
    border-top: 1px solid #222440;
}
</style>
