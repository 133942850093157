<template>
    <Link
        :href="'proposal/' + proposal.id"
        class="border border-gray-dark proposal hover:bg-gray-dark transform cursor-pointer w-full grid grid-cols-1"
    >
        <div class="grid lg:grid-cols-2 ">
            <div class="flex justify-start items-center">
                <img
                    src="https://ipfs-fast.b-cdn.net/shiryo-dao/shiryo-icon.png"
                />
                <h2
                    class="text-gray-lighter uppercase proposal-heading ml-4 flex items-center justify-start"
                >
                    {{ proposal.name }}
                    <span
                        v-if="proposal.archived"
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs ml-4 bg-dark-lighter text-gray font-extrabold"
                    >
                        Archived
                    </span>
                </h2>
            </div>
            <div class="proposal-meta">
                <proposal-meta :proposal="proposal" />
            </div>
        </div>
        <div class="text-gray  proposal-description mb-4 max-w-2xl">
            {{ descriptionAsString }}<span v-if="descriptionAsString.length >= 280">...</span>
        </div>
    </Link>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";
import ProposalMeta from "./ProposalMeta.vue";
import { stripHtml } from "string-strip-html";

export default {
    name: "ProposalSummary",
    components: { Link, ProposalMeta },
    props: {
        proposal: Object,
    },
    computed: {
        descriptionAsString() {
            return stripHtml(this.proposal.description).result.slice(0, 280);
        },
    },
};
</script>

<style lang="scss" scoped>

.proposal {
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 20px;
}

@media (min-width: 640px) {
    .proposal {
        padding: 30px 35px;
    }
}
.proposal-meta {
}
.proposal-heading {
    font-size: 18px;
    line-height: 23.81px;
    font-weight: 700;
}
.proposal-description {
    font-size: 14px;
    line-height: 19.5px;
    font-weight: 400;
    margin-top: 20px;
}
</style>
