<template>
    <div>
        <no-tokens-modal />
        <div class="mb-8 ml-1">
            <Link href="/" class="text-gray back-button hover:bg-gray-dark transition hover:text-white">
                <i class="fa-solid fa-angle-left mr-4"></i> Go Back
            </Link>
        </div>
        <div class="grid grid-cols-3">
            <div class="lg:col-span-2 col-span-3">
                <proposal-card  />
            </div>
            <div class="lg:col-span-1 col-span-3">
                <votes-card  />
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "../components/Layout.vue";
import ProposalCard from '../components/ProposalCard.vue'
import VotesCard from '../components/VotesCard.vue'
import { Link } from '@inertiajs/inertia-vue3'
import NoTokensModal from '../components/NoTokensModal.vue';

export default {
    name: "Proposal",
    layout: Layout,
    props:{
        proposal: Object,
        votes: Array,
        active: String
    },
    mounted(){
        this.$store.commit('addVotes', this.votes);
        this.$store.commit('addProposal', this.proposal);
        this.$store.commit('setActiveProposalId', this.active);
    },
    components:{
        ProposalCard, Link, VotesCard, NoTokensModal
    },
};
</script>

<style lang="scss" scoped>
.back-button {
border: 1px solid #1D1E2E;
border-radius: 5px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 30px;
width: 183px;
}
</style>
