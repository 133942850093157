<template>
    <div>
        <div class="flex justify-between text-white mb-4">
            <h4 style="font-size: 12px;font-weight:700;letter-spacing: 0.09em">RECENTLY VOTED</h4>
            <h4 style="font-size: 12px">{{votes.length}} Votes</h4>
        </div>
        <ul>
            <vote-history-item v-for="vote, key in limitedVotes" :key="key" :vote="vote" />
        </ul>
    </div>
</template>

<script>
    import VoteHistoryItem from './VoteHistoryItem.vue'
    import take from 'lodash/take';

    export default {
        name:"VoteHistory",
        props:{
            votes:{}
        },
        computed:{
            limitedVotes(){
                if(this.votes.length > 25) {
                    return take(this.votes, 20);
                }
                return this.votes;
            }
        },
        components:{
            VoteHistoryItem
        }
    }
</script>

<style lang="scss" scoped>

</style>
