<template>
    <div class="flex" style="margin-right: 35px">
        <div class="rounded-full mr-4 flex items-center justify-center overflow-hidden" style="background: #222738;padding: 6px;">
            <img src="https://ipfs-fast.b-cdn.net/shiryo-dao/favicon.png" />
        </div>
        <div style="width: 150px;" class="flex items-center justify-end">
            <button v-if="! $store.state.authenticated_wallet_address" @click="login" class="connect-button w-full text-white bg-primary">
                CONNECT
            </button>
            <p v-else class="text-gray truncate">{{ $store.state.authenticated_wallet_address }}</p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Web3 from "web3/dist/web3.min.js";
import Web3Modal from "web3modal";

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {}, // add additional providers here, like WalletConnect, Coinbase Wallet, etc.
});

export default {
    name: "WalletConnect",
    methods: {
        async login() {
            if (!window.ethereum) {
                alert(
                    "MetaMask not detected. Please try again from a MetaMask enabled browser."
                );
            }

            const web3 = new Web3(window.ethereum);

            const message = [
                "Please sign this message to connect your wallet to the Shiryo Dao",
            ].join("\n");

            const address = (await web3.eth.requestAccounts())[0];
            const signature = await web3.eth.personal.sign(message, address);

            const req = await axios.post("/login", {
                message,
                address,
                signature,
            });

            this.$store.commit("setAuthWalletAddress", req.data.address);
            this.$store.commit('setIsAdmin', req.data.isAdmin);
        },
    },
};
</script>

<style lang="scss" scoped>
.connect-button {
    padding: 10px 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}
</style>
