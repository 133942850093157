import { createStore } from 'vuex'
import keyBy from 'lodash/keyBy';
import { forEach, orderBy } from 'lodash';
import filter from 'lodash/filter';

// Create a new store instance.
const store = createStore({
  state () {
    return {
      authenticated_wallet_address: false,
      votes:{},
      proposals:{},
      activeProposal:false,
      showNoBalanceModal: false,
      isAdmin: false
    }
  },
  getters: {
    proposal(state){
        return (state.activeProposal) ? state.proposals[state.activeProposal] : false
    },
    votesForProposal(state){
        if(! state.activeProposal) return [];
        return orderBy( filter(
            state.votes, {proposal_id: state.activeProposal}
        ), 'created_at', 'desc')
    }
  },
  mutations: {
    setAuthWalletAddress (state, address) {
      state.authenticated_wallet_address = address;
    },
    setVotes(state, votes) {
        state.votes = keyBy(votes, "id");
    },
    setProposals(state, proposals){
        state.proposals = keyBy(proposals, "id");
    },
    addProposal(state, proposal){
        if(! proposal) return;

        if(! state.proposals[proposal.id]){
            state.proposals[proposal.id] = proposal;
        }
    },
    addVotes(state, votes){
        if(! votes) return;

        forEach(votes, vote => {
            if(! state.votes[vote.id]){
                state.votes[vote.id] = vote;
            }
        });
    },
    setActiveProposalId(state, id){
        state.activeProposal = parseInt(id);
    },
    setShowNoBalanceModal(state, value){
        state.showNoBalanceModal = value;
    },
    setIsAdmin(state, val){
        state.isAdmin = val;
    }
  }
})

export default store;
