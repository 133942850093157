<template>
    <h1 class="text-gray-light font-display  proposals-heading">DAO</h1>
    <ul>
        <proposal-summary
            v-for="proposal in proposals.data"
            :proposal="proposal"
            :key="proposal.id"
        />
    </ul>
</template>

<script>
    import Layout from '../components/Layout.vue'
    import ProposalSummary from '../components/ProposalSummary.vue'

    export default {
        name:"Proposals",
        layout: Layout,
        components:{
            ProposalSummary
        },
        props:{
            proposals: Object
        },
        mounted(){
        }
    }
</script>

<style lang="scss" scoped>
.proposals-heading {
    font-size: 30px;
    line-height: 26.64px;
    font-weight: 400;
    margin-bottom : 35px;
}
</style>
